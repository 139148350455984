//The bigger CIRCLE_SIZE is the smaller it will be,

//import useWindowDimensions from "../hooks/useWindowDimensions";

// 1 fits the whole canvas
const CIRCLE_SIZE = 2.8;
const CENTER_CIRCLE = 2; //2 is in the center of the canvas
export const CANVAS_WIDTH = 600;
export const CANVAS_HEIGHT = 600;
const fontSize = 18;
const LINE_WIDTH = 1;

export let scoreLocation = [];

export const resetLocations = () => {
  scoreLocation = [];
};

// Define colors for each section
export const colors = [
  "#007a8a", // -
  "#61002d", // -
  "#25aa2a", // +
  "#760c8b", // +
  "#555555", // -
  "#27D6C5", // +
];

const categories = ["Σχέσεις", "Εργασία/Εκπαίδευση", "Υγεία", "Οικογένεια"];

export const dartBoardValues = [
  [5, 4, 3, 2, 1],
  [5, 4, 3, 2, 1],
  [5, 4, 3, 2, 1],
  [5, 4, 3, 2, 1],
];

export const boardData = {
  centerX: (item) => item.width / CENTER_CIRCLE,
  centerY: (item) => item.width / CENTER_CIRCLE,
  radius: (item) => item.width / CIRCLE_SIZE,
  size: () => dartBoardValues.length,
};

export const getDartValue = (x, y, centerX, centerY, radius) => {
  let score;
  let sameAngle = false;
  // Determine value of clicked area
  const distance = Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2);
  if (distance > radius) {
    // Clicked outside the dart board
    return 0;
  } else if (distance < radius * 0.02) {
    // Clicked on the bullseye
    return 50;
  } else {
    const distance = Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2);

    // Find the index of the triangle that contains the point
    //const angle = Math.atan2(y - centerY + 0.5, x - centerX + 0.5);

    const index = Math.floor(
      (Math.atan2(y - centerY + 0.5, x - centerX + 0.5) + Math.PI) /
        (Math.PI / (dartBoardValues.length / 2))
    );

    //console.log(index);

    // Use the distance to determine which value to return
    if (distance <= radius / 5) {
      score = dartBoardValues[index][0];
    } else if (distance <= (radius * 2) / 5) {
      score = dartBoardValues[index][1];
    } else if (distance <= (radius * 3) / 5) {
      score = dartBoardValues[index][2];
    } else if (distance <= (radius * 4) / 5) {
      score = dartBoardValues[index][3];
    } else {
      score = dartBoardValues[index][4];
    }

    if (scoreLocation.length > 0) {
      scoreLocation.forEach((element) => {
        if (element[3] === index) {
          sameAngle = true;
          element[0] = x;
          element[1] = y;
          element[2] = score;
        }
      });
    }

    if (!sameAngle) scoreLocation.unshift([x, y, score, index]);
    return score;
  }
};

export const drawBoard = (canvas, ctx, minRadiusAdjustment, xImage) => {
  const TRIANGLES = dartBoardValues.length;
  const centerX = boardData.centerX(canvas);
  const centerY = boardData.centerY(canvas);
  const radius = boardData.radius(canvas);

  // Draw the background
  ctx.fillStyle = "#152a68";
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  for (let i = 0; i < TRIANGLES; i++) {
    // Calculate the angle of the midpoint of the current value triangle
    const valueAngle = i * ((2 * Math.PI) / TRIANGLES) + Math.PI / TRIANGLES;

    // Calculate the starting and ending angles for the current color triangle
    const colorStartAngle = valueAngle - Math.PI / TRIANGLES;
    const colorEndAngle = valueAngle + Math.PI / TRIANGLES;

    // Draw the color triangle
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, radius, colorStartAngle, colorEndAngle);
    ctx.closePath();
    ctx.fillStyle = colors[i];
    ctx.fill();
  }

  //Draw the value text
  for (let i = 0; i < TRIANGLES; i++) {
    const valueAngle =
      i * ((2 * Math.PI) / TRIANGLES) + Math.PI / TRIANGLES + Math.PI;
    const values = dartBoardValues[i];
    // const maxRadius = radius - maxRadiusAdjustment/CIRCLE_SIZE;
    // initial displacement from the radious
    const minRadius = minRadiusAdjustment / CIRCLE_SIZE;
    // distance of every other number from the center
    const radiusStep = (radius - minRadius / values.length) / values.length;

    for (let j = 0; j < values.length; j++) {
      const value = values[j];
      const textX =
        centerX + (minRadius + j * radiusStep) * Math.cos(valueAngle);
      const textY =
        centerY + (minRadius + j * radiusStep) * Math.sin(valueAngle);
      ctx.font = `bold ${fontSize}px Arial`;
      ctx.fillStyle = "#ffffff";
      ctx.textAlign = "center";
      ctx.fillText(value, textX, textY + 6);
    }
  }

  // Draw X for stored dart hits
  const image = new Image();
  image.src = xImage;
  image.onload = () => {
    for (let i = 0; i < scoreLocation.length; i++) {
      ctx.drawImage(
        image,
        scoreLocation[i][0] - 10,
        scoreLocation[i][1] - 10,
        20,
        20
      );
    }
  };

  const INNER_RADIUS = radius / dartBoardValues[0].length;
  //const OUTER_RADIUS = radius;
  //draw tiny circle in the middle
  ctx.beginPath();
  ctx.arc(centerX, centerY, radius * 0.02, 0, 2 * Math.PI);
  ctx.fillStyle = "#ffffff";
  ctx.fill();

  // Draw separators
  for (let i = 1; i < dartBoardValues[0].length + 1; i++) {
    ctx.beginPath();
    ctx.arc(centerX, centerY, INNER_RADIUS * i, 0, 2 * Math.PI);
    ctx.lineWidth = LINE_WIDTH;
    if (i === dartBoardValues[0].length) ctx.strokeStyle = "#707070";
    else ctx.strokeStyle = "#000000";
    ctx.stroke();
  }

  // draw categories
  for (let i = 0; i < TRIANGLES; i++) {
    const valueAngle =
      i * ((2 * Math.PI) / TRIANGLES) + Math.PI / TRIANGLES + Math.PI;

    const values = dartBoardValues[i];
    ctx.font = `bold ${fontSize}px Arial`;
    ctx.fillStyle = "#ffffff";
    ctx.textAlign = "center";

    // save the current state of the canvas context
    ctx.save();

    // rotate the canvas context around the origin point
    ctx.translate(centerX, centerY);
    ctx.rotate(valueAngle);
    ctx.translate(radius + radius / values.length, 0);

    // rotate the canvas context by an additional 90 or 270 degrees
    if (i + 1 > TRIANGLES / 2) ctx.rotate((3 * Math.PI) / 2);
    else ctx.rotate(Math.PI / 2);

    // draw the text
    ctx.fillText(categories[i], 0, 0);

    // restore the original state of the canvas context
    ctx.restore();
  }
};

export const drawDart = (dartPos, dartSvg, ctx, canvasSize) => {
  const maxScale = 1; // Maximum scale value
  const scale = Math.min(canvasSize / 600, maxScale); // Calculate the scale based on canvas size with a maximum limit
  const dartSize = 80 * scale; // Adjust the dart size based on the scaling factor
  const offset = dartSize;

  if (dartPos !== null) {
    const [x, y] = dartPos;
    const dartImage = new Image();
    dartImage.src = dartSvg;
    dartImage.onload = () => {
      ctx.drawImage(dartImage, x, y - offset, dartSize, dartSize);
    };
  }
};

import React, { useRef, useEffect, useState } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  getDartValue,
  boardData,
  drawBoard,
  drawDart,
  scoreLocation,
  resetLocations,
} from "../helper/dartBoardData";
import dartSvg from "../img/dart.svg";
import xImage from "../img/x.png";

const DartBoard = ({ setPartFinished, resetDartPos }) => {
  const { height, width } = useWindowDimensions();
  let canvasSize = Math.min(height, width); // Calculate the smaller dimension
  canvasSize = Math.min(canvasSize, 600); // Limit the maximum size to 600
  //console.log(canvasSize);
  //console.log(width);
  const canvasRef = useRef(null);
  const [dartPos, setDartPos] = useState(null);
  const [score, setScore] = useState(0);

  useEffect(() => {
    resetLocations();
  }, []);

  useEffect(() => {
    // if (reset === true) {
    //   setDartPos(null);
    //   setReset(false);
    // }
    //console.log(width); // TODO REMOVE THE LOG ---
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    // Clear the canvas with a white background
    ctx.fillStyle = "#152a68";
    ctx.fillRect(0, 0, width, width);

    //const for canvas
    const centerX = boardData.centerX(canvas);
    const centerY = boardData.centerY(canvas);
    const radius = boardData.radius(canvas);
    const triangles = boardData.size();

    //Draw the dart board
    drawBoard(canvas, ctx, 50, xImage);

    // Draw the dart
    drawDart(dartPos, dartSvg, ctx, canvasSize);

    // Add event listener for mouse clicks/touches
    const handleClick = (event) => {
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      // Check if click is inside the circle
      const distance = Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2);
      if (distance <= radius) {
        const newScore = getDartValue(x, y, centerX, centerY, radius);
        //console.log(scoreLocation); // TODO REMOVE THE LOG ---
        setDartPos([x, y]);
        setScore(newScore);
      }
    };

    // Only for testing
    if (scoreLocation.length >= triangles) setPartFinished(true);
    canvas.addEventListener("click", handleClick);

    // Clean up event listeners
    return () => {
      //setDartPos(null)
      canvas.removeEventListener("click", handleClick);
    };
  }, [dartPos, setPartFinished, width, resetDartPos]);

  // const cDimension =
  //   canvasSize.width < canvasSize.height ? canvasSize.width : canvasSize.height;
  // const fontSize = cDimension / 30; // Adjust the font size based on canvas size

  return (
    <div className="flex items-center justify-center flex-col max-w-3xl mt-5">
      <div className=" text-center mr-1 ml-1">
        Όσο πιο κοντά στο κέντρο (δηλ., στο 5) <br></br> τόσο πιο σημαντικό
        είναι για εσάς
      </div>
      <br></br>
      <canvas ref={canvasRef} width={canvasSize} height={canvasSize} />
      {/*<div>Score: {score}</div>*/}
    </div>
  );
};

export default DartBoard;

import VideoPlayer from "../components/VideoPlayer";
import SessionEnding from "../components/SessionEnding";
import Base25 from "../components/Base25";
import Questionnaires from "../components/Questionnaires";
import DartBoard from "../components/DartBoard";
import Table from "../components/Table";
import Instructions from "../components/Instructions";
import TextBoxes from "../components/TextBoxes";
import TexBoxesQuestions from "../components/ui/TexBoxesQuestions";

const sides1 = [
  "Το επίπεδο στο οποίο βρίσκομαι",
  "Το επίπεδο που θέλω να φτάσω",
  "Τι μπορώ να κάνω για να μειώσω τη διαφορά",
];
const headers1 = [
  "Στις Σχέσεις",
  "Στην Εργασία/Εκπαίδευση",
  "Στην Υγεία",
  "Στην Οικογένεια",
];

const S1P2Paragraph = (
  <div className="mb-8">
    Για να επιτύχετε οποιαδήποτε αλλαγή στη συμπεριφορά ή τη ζωή σας, θα πρέπει
    να εξασκηθείτε και να δοκιμάσετε μερικά από τα πράγματα που συζητάμε εδώ,
    ακόμη και όταν δεν είστε συνδεδεμένοι σε αυτόν τον ιστότοπο. <br></br>
    <br></br> Θα μιλήσουμε περισσότερο για αυτό αργότερα… Το πρόγραμμα θα
    καταγράψει όλα όσα συζητάμε εδώ. Ωστόσο, οι πληροφορίες που παρέχετε θα
    αποθηκευτούν σε έναν κωδικό και δεν θα συνδέονται με το όνομά σας. Όλα όσα
    θα συμπληρώσετε θα είναι εμπιστευτικά. <br></br> <br></br> Τώρα, θα σας
    ζητήσω να συμπληρώσετε μερικά ερωτηματολόγια, τα οποία θα με βοηθήσουν να
    μάθω περισσότερα για εσάς και τη σχέση σας με το κάπνισμα.
  </div>
);

const S4P1Paragraph = (
  <div className="mb-8">
    Πως ήταν η εβδομάδα σου; Θα ξεκινήσουμε αυτή τη συνεδρία με ένα
    ερωτηματολόγιο.
  </div>
);

const S5P1Paragraph = (
  <div className="mb-8">
    Σήμερα θέλω να ξεκινήσω αμέσως με μερικές ερωτήσεις, για να δω πόσο καλά τα
    πάτε με το πρόγραμμα και να βεβαιωθώ ότι το πρόγραμμα σας βοηθά.
  </div>
);

const S5P1Questions = [
  "Ποιους τρόπους μπορείτε να χρησιμοποιήσετε για να αντιμετωπίσετε εσωτερικά ερεθίσματα, όπως σκέψεις, συναισθήματα και σωματικά συμπτώματα;",
  "Ποιους τρόπους μπορείτε να χρησιμοποιήσετε για να αντιμετωπίσετε εξωτερικά ερεθίσματα;",
  "Τι άλλο μπορεί να σας εμποδίσει να πετύχετε;",
  "Πώς θα αντιμετωπίσετε αυτά τα εμπόδια, αν εμφανιστούν;",
  "Πώς θα ανταμείψετε τον εαυτό σας αν πετύχετε; Σκεφτείτε κάτι που θα σας δώσει περισσότερα κίνητρα για επιτυχία.",
];

const S6P1Paragraph = (
  <div className="mb-8">
    Σήμερα είναι η τελευταία μας συνεδρία με το πρόγραμμα BioNic! <br></br>
    <br></br>
    Παρακαλώ συμπληρώστε για άλλη μια φορά το ακόλουθο ερωτηματολόγιο.
  </div>
);

const S1E = (
  <div>
    <div className=" text-center mb-2">
      Η 1<sup>η</sup> Συνεδρία oλοκληρώθηκε.
    </div>
    Παρακαλώ σημειώστε κάπου την ημερομηνία που καπνίσατε ξανά έστω και μία φορά
    στην περίπτωση που δεν καταφέρετε να αντισταθείτε. <br></br> <br></br>
    Περιμένω να σας δω ξανά στην επόμενη συνεδρία σε επτά με δέκα ημέρες!
  </div>
);

const S2E = (
  <div>
    <div className=" text-center mb-2">
      Η 2<sup>η</sup> Συνεδρία oλοκληρώθηκε.
    </div>
    Παρακαλώ σημειώστε κάπου την ημερομηνία που καπνίσατε ξανά έστω και μία φορά
    στην περίπτωση που δεν καταφέρετε να αντισταθείτε. <br></br> <br></br>
    Περιμένω να σας δω ξανά στην επόμενη συνεδρία σε επτά με δέκα ημέρες!
  </div>
);

const S3E = (
  <div>
    <div className=" text-center mb-2">
      Η 3<sup>η</sup> Συνεδρία oλοκληρώθηκε.
    </div>
    Παρακαλώ σημειώστε κάπου την ημερομηνία που καπνίσατε ξανά έστω και μία φορά
    στην περίπτωση που δεν καταφέρετε να αντισταθείτε. <br></br> <br></br>
    Περιμένω να σας δω ξανά στην επόμενη συνεδρία σε επτά με δέκα ημέρες!
  </div>
);

const S4E = (
  <div>
    <div className=" text-center mb-2">
      Η 4<sup>η</sup> Συνεδρία oλοκληρώθηκε.
    </div>
    Παρακαλώ σημειώστε κάπου την ημερομηνία που καπνίσατε ξανά έστω και μία φορά
    στην περίπτωση που δεν καταφέρετε να αντισταθείτε. <br></br> <br></br>
    Περιμένω να σας δω ξανά στην επόμενη συνεδρία σε επτά με δέκα ημέρες!
  </div>
);

const S5E = (
  <div>
    <div className=" text-center mb-2">
      Η 5<sup>η</sup> Συνεδρία oλοκληρώθηκε.
    </div>
    Παρακαλώ σημειώστε κάπου την ημερομηνία που καπνίσατε ξανά έστω και μία φορά
    στην περίπτωση που δεν καταφέρετε να αντισταθείτε. <br></br> <br></br>
    Περιμένω να σας δω ξανά στην επόμενη συνεδρία σε επτά με δέκα ημέρες!
  </div>
);

const S6E = (
  <div>
    <div className=" text-center mb-2">
      Η 6<sup>η</sup> Συνεδρία oλοκληρώθηκε.
    </div>
    Θα έχετε για πάντα αυτή την ιστορία που δημιουργήσαμε μαζί σαν σύμμαχό σας.
    <br></br>
    <br></br>
    Ευχαριστώ που συμμετείχατε και καλή τύχη στη διακοπή του καπνίσματος!
  </div>
);

const SessionData = [
  // Session 1
  [
    // S1 P2
    {
      data: { source: "./video/session1/step_2.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: {
        title: <b>Οδηγίες</b>,
        paragraph: S1P2Paragraph,
      },
      isPartFinished: true,
      component: ({ title, paragraph }) => (
        <Instructions title={title} paragraph={paragraph} />
      ),
      props: (data) => ({ title: data.title, paragraph: data.paragraph }),
    },
    // S1 P3
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <Questionnaires setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    //S1 P4
    {
      data: { source: "./video/session1/step_4_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { cardsShowed: false },
      isPartFinished: false,
      component: ({ setPartFinished, cardsShowed }) => (
        <Base25 setPartFinished={setPartFinished} cardsShowed={cardsShowed} />
      ),
      props: (data) => ({ cardsShowed: data.cardsShowed }),
    },
    {
      data: { source: "./video/session1/step_4_2.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { cardsShowed: true },
      isPartFinished: false,
      component: ({ setPartFinished, cardsShowed }) => (
        <Base25 setPartFinished={setPartFinished} cardsShowed={cardsShowed} />
      ),
      props: (data) => ({ cardsShowed: data.cardsShowed }),
    },
    // S1 P5
    {
      data: { source: "./video/session1/step_5.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    // S1 P6
    {
      data: { source: "./video/session1/step_6_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session1/step_6_2.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <DartBoard setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    {
      data: { source: "./video/session1/step_6_3.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <DartBoard setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    //S1 P7
    {
      data: { source: "./video/session1/step_7_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { headers: headers1, sides: sides1 },
      isPartFinished: false,
      component: ({ headers, sides, setPartFinished }) => (
        <Table
          setPartFinished={setPartFinished}
          headers={headers}
          sides={sides}
        />
      ),
      props: (data) => ({ headers: data.headers, sides: data.sides }),
    },
    {
      data: { source: "./video/session1/step_7_2.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session1/step_7_3.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    //S1 P8
    {
      data: { source: "./video/session1/step_8.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    //S1 P10
    {
      data: { session: S1E },
      isPartFinished: false,
      component: ({ session, setPartFinished, time, isLastSession }) => (
        <SessionEnding
          session={session}
          setPartFinished={setPartFinished}
          time={time}
          isLastSession={isLastSession}
        />
      ),
      props: (data) => ({ session: data.session }),
    },
    // todo wait for your confirm this part has video and board 25. Option split in 2 different parts?
    // ! Question should we manually set witch part is the user for example we can set 3.1 etc into the database?
  ],
  // Session 2
  [
    // S2 P1
    {
      data: { source: "./video/session2/step_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    // S2 P2
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <Questionnaires setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    // S2 P3
    {
      data: { source: "./video/session2/step_3_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { headers: headers1, sides: sides1 },
      isPartFinished: false,
      component: ({ headers, sides, setPartFinished }) => (
        <Table
          setPartFinished={setPartFinished}
          headers={headers}
          sides={sides}
        />
      ),
      props: (data) => ({ headers: data.headers, sides: data.sides }),
    },
    {
      data: { source: "./video/session2/step_3_3.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <DartBoard setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    // S2 P5
    {
      data: { source: "./video/session2/step_5_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session2/step_5_2.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    // S2 p6
    {
      data: { source: "./video/session2/step_6_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: {
        text: "Γράψτε 5 εξωτερικά ερεθίσματα που σας κάνουν να θέλετε να καπνίσετε. Όταν συμπληρώσετε όλα τα κουτάκια, επιλέξτε «Αποθήκευση» για να συνεχίσετε.",
        outer: "save",
      },
      isPartFinished: false,
      component: ({ setPartFinished, text, outer }) => (
        <TextBoxes
          setPartFinished={setPartFinished}
          text={text}
          outer={outer}
        />
      ),
      props: (data) => ({ text: data.text, outer: data.outer }),
    },
    {
      data: { source: "./video/session2/step_6_2.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: {
        text: "Τώρα, γράψτε σε κάθε κουτί, έναν τρόπο που θα μπορούσατε να χρησιμοποιήσετε για να αντιμετωπίσετε κάθε ερέθισμα που σας οδηγεί στο κάπνισμα.",
        outer: "get",
      },
      isPartFinished: false,
      component: ({ setPartFinished, text, outer }) => (
        <TextBoxes
          setPartFinished={setPartFinished}
          text={text}
          outer={outer}
        />
      ),
      props: (data) => ({ text: data.text, outer: data.outer }),
    },
    // S2 P7
    {
      data: { source: "./video/session2/step_7_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: {
        text: "Παρακαλώ σημειώστε παραδείγματα σκέψεων ή συναισθημάτων που μπορεί να σας οδηγήσουν στο κάπνισμα. Όταν συμπληρώσετε όλα τα κουτάκια, επιλέξτε «Αποθήκευση» για να συνεχίσετε.",
        inner: "save",
      },
      isPartFinished: false,
      component: ({ setPartFinished, text, inner }) => (
        <TextBoxes
          setPartFinished={setPartFinished}
          text={text}
          inner={inner}
        />
      ),
      props: (data) => ({ text: data.text, inner: data.inner }),
    },
    {
      data: { source: "./video/session2/step_7_2.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: {
        text: "Τι θα κάνατε για να αντιμετωπίσετε κάθε συγκεκριμένο ερέθισμα για να αντισταθείτε στο κάπνισμα;",
        inner: "get",
      },
      isPartFinished: false,
      component: ({ setPartFinished, text, inner }) => (
        <TextBoxes
          setPartFinished={setPartFinished}
          text={text}
          inner={inner}
        />
      ),
      props: (data) => ({ text: data.text, inner: data.inner }),
    },
    // S2 P8
    {
      data: { source: "./video/session2/step_8.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    // S2 P9
    {
      data: { source: "./video/session2/step_9.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    // S2 P12
    {
      data: { session: S2E },
      isPartFinished: false,
      component: ({ session, setPartFinished, time, isLastSession }) => (
        <SessionEnding
          session={session}
          setPartFinished={setPartFinished}
          time={time}
          isLastSession={isLastSession}
        />
      ),
      props: (data) => ({ session: data.session }),
    },
  ],
  // Session 3
  [
    // S3 P1
    {
      data: { source: "./video/session3/step_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    // S3 P2
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <Questionnaires setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    // S3 P3
    {
      data: { source: "./video/session3/step_3_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session3/step_3_2.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <DartBoard setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    {
      data: { source: "./video/session3/step_3_3.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session3/step_3_4_lie_detector.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    // S3 P4
    {
      data: { source: "./video/session3/step_4_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session3/step_4_2.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session3/step_4_3.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    // S3 P5
    {
      data: { source: "./video/session3/step_5.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    //S3 P6
    {
      data: { source: "./video/session3/step_6.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { session: S3E },
      isPartFinished: false,
      component: ({ session, setPartFinished, time, isLastSession }) => (
        <SessionEnding
          session={session}
          setPartFinished={setPartFinished}
          time={time}
          isLastSession={isLastSession}
        />
      ),
      props: (data) => ({ session: data.session }),
    },
  ],
  // Session 4
  [
    // S4 P1
    {
      data: {
        title: <b>Γειά σου!</b>,
        paragraph: S4P1Paragraph,
      },
      isPartFinished: true,
      component: ({ title, paragraph }) => (
        <Instructions title={title} paragraph={paragraph} />
      ),
      props: (data) => ({ title: data.title, paragraph: data.paragraph }),
    },
    // S4 P2
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <Questionnaires setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    //S4 P3
    {
      data: { source: "./video/session4/step_3_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session4/step_3_2.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <DartBoard setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    //S4 P4
    {
      data: { source: "./video/session4/step_4.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    //S4 P5
    {
      data: { source: "./video/session4/step_5.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    //S4 P6
    {
      data: { source: "./video/session4/step_6.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    //S4 P7
    {
      data: { source: "./video/session4/step_7.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    //S4 P11
    {
      data: { session: S4E },
      isPartFinished: false,
      component: ({ session, setPartFinished, time, isLastSession }) => (
        <SessionEnding
          session={session}
          setPartFinished={setPartFinished}
          time={time}
          isLastSession={isLastSession}
        />
      ),
      props: (data) => ({ session: data.session }),
    },
  ],
  //Session 5
  [
    // S5 P1
    {
      data: {
        title: <b>Γεια σου και πάλι!</b>,
        paragraph: S5P1Paragraph,
      },
      isPartFinished: true,
      component: ({ title, paragraph }) => (
        <Instructions title={title} paragraph={paragraph} />
      ),
      props: (data) => ({ title: data.title, paragraph: data.paragraph }),
    },
    {
      data: {
        questions: S5P1Questions,
      },
      isPartFinished: false,
      component: ({ setPartFinished, questions }) => (
        <TexBoxesQuestions
          setPartFinished={setPartFinished}
          questions={questions}
        />
      ),
      props: (data) => ({ questions: data.questions }),
    },
    //S5 P2
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <Questionnaires setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    //S5 P3
    {
      data: { source: "./video/session5/step_3_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session5/step_3_2.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <DartBoard setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    //S5 P4
    {
      data: { source: "./video/session5/step_4.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    //S5 P6
    {
      data: { source: "./video/session5/step_6.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { session: S5E },
      isPartFinished: false,
      component: ({ session, setPartFinished, time, isLastSession }) => (
        <SessionEnding
          session={session}
          setPartFinished={setPartFinished}
          time={time}
          isLastSession={isLastSession}
        />
      ),
      props: (data) => ({ session: data.session }),
    },
  ],
  //Session 6
  [
    //S6 P1
    {
      data: {
        title: <b>Καλως ΗΡΘΑΤΕ!</b>,
        paragraph: S6P1Paragraph,
      },
      isPartFinished: true,
      component: ({ title, paragraph }) => (
        <Instructions title={title} paragraph={paragraph} />
      ),
      props: (data) => ({ title: data.title, paragraph: data.paragraph }),
    },
    //S6 P2
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <Questionnaires setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    //S6 P3
    {
      data: { source: "./video/session6/step_3_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session6/step_3_2.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: null,
      isPartFinished: false,
      component: ({ setPartFinished }) => (
        <DartBoard setPartFinished={setPartFinished} />
      ),
      props: (data) => null,
    },
    //S6 P4
    {
      data: { source: "./video/session6/step_4.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    //S6 P5
    {
      data: { source: "./video/session6/step_5_2_waves.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session6/step_5_3.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session6/step_6.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session6/step_8.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session6/step_9.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { source: "./video/session6/step_12_1.mp4" },
      isPartFinished: false,
      component: ({ source, setPartFinished }) => (
        <VideoPlayer source={source} setPartFinished={setPartFinished} />
      ),
      props: (data) => ({ source: data.source }),
    },
    {
      data: { session: S6E },
      isPartFinished: false,
      component: ({ session, setPartFinished, time, isLastSession }) => (
        <SessionEnding
          session={session}
          setPartFinished={setPartFinished}
          time={time}
          isLastSession={isLastSession}
        />
      ),
      props: (data) => ({ session: data.session }),
    },
  ],
];

export { SessionData };

import className from "classnames";
import { useState } from "react";

function Button({
  children,
  primary,
  secondary,
  success,
  warning,
  danger,
  outline,
  rounded,
  isInvisible,
  ...rest
}) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);

    setTimeout(() => {
      rest.onClick && rest.onClick();
    }, 50); //250ms is the duration of the animation
  };

  const classes = className(
    rest.className,
    "flex items-center px-3 py-1.5 border mx-2 font-bold overflow-hidden",
    {
      "border-blue-500 bg-blue-500 text-white": primary,
      "border-gray-900 bg-gray-900 text-white": secondary,
      "border-green-500 bg-green-500 text-white": success,
      "border-yellow-400 bg-yellow-400 text-white": warning,
      "border-red-500 bg-red-500 text-white": danger,
      "rounded-xl": rounded,
      "bg-white": outline,
      "!text-blue-500": outline && primary,
      "!text-gray-900": outline && secondary,
      "!text-green-500": outline && success,
      "!text-yellow-400": outline && warning,
      "!text-red-500": outline && danger,
      "active:opacity-20": !rest.disabled,
      hidden: isInvisible, // Add "hidden" class if isInvisible is true
    }
  );

  return (
    <button {...rest} className={classes} onClick={handleClick}>
      {children}
    </button>
  );
}

Button.propTypes = {
  checkVariationValue: ({ primary, secondary, success, warning, danger }) => {
    const count =
      Number(!!primary) +
      Number(!!secondary) +
      Number(!!warning) +
      Number(!!success) +
      Number(!!danger);

    if (count > 1) {
      return new Error(
        "Only one of primary, secondary, success, warning, danger can be true"
      );
    }
  },
};

export default Button;

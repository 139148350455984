import React, { useState } from "react";

const TexBoxesQuestions = ({ setPartFinished, questions }) => {
  const [inputs, setInputs] = useState(Array(questions.length).fill(""));

  const handleInputChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);

    const allValuesFilled = newInputs.every((value) => value.trim() !== "");

    setPartFinished(allValuesFilled);
  };
  const allValuesFilled = inputs.every((value) => value.trim() !== "");
  return (
    <div className="">
      {questions.map((question, index) => (
        <div
          key={index}
          className=" border-b border-gray-200 flex flex-col items-center justify-center mx-4 "
        >
          <p className="px-4  py-2 text-justify">{question}</p>
          <div key={index} className=" px-4 mb-4 flex-row flex items-center">
            {/* <div className="mr-2">{index + 1}</div> */}

            <input
              key={index}
              className="border border-gray-300 rounded px-4 py-2 text-gray-800"
              value={inputs[index]}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
          </div>
        </div>
      ))}
      {!allValuesFilled && (
        <div className=" text-center mr-3 ml-3 mt-3 mb-2 font-bold text-red-500">
          Παρακαλώ απαντήστε σε όλες τις ερωτήσεις για να προχωρήσετε
        </div>
      )}
    </div>
  );
};

export default TexBoxesQuestions;

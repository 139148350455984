//import Base25 from "../components/Base25";
//import Questionnaires from "../components/Questionnaires";
import React, { useState, useEffect } from "react";
import SessionViewer from "../components/SessionViewer";
import { SessionData } from "../helper/sessionsData";
import {
  updatePart,
  updateSession,
  getPart,
  getSession,
} from "../services/apis";
import useCookie from "../hooks/useCookie";
import LoadingSpinner from "../components/ui/LoadingSpinner";
import { useNavigate } from "react-router-dom";

function SessionsPage() {
  const [token] = useCookie("token");
  const [currentSessionIndex, setCurrentSessionIndex] = useState(0);
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // add a loading state
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const session = await getSession(token);
        const part = await getPart(token);

        setCurrentSessionIndex(session);
        setCurrentPartIndex(part);
      } catch (error) {
        alert("Error fetching data, please login again.");
        navigate("/login");
      } finally {
        setIsLoading(false);
      }
    }
    // Call the fetchData function when the component mounts
    fetchData();
  }, [token, navigate]);

  useEffect(() => {
    if (currentSessionIndex !== 0 || currentPartIndex !== 0) {
      updateSession(token, currentSessionIndex).catch((error) => {
        console.error("Failed to update session:", error);
      });
      updatePart(token, currentPartIndex).catch((error) => {
        console.error("Failed to update part:", error);
      });
    }
  }, [token, currentSessionIndex, currentPartIndex]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  // TODO COLORS
  return (
    <div
      className={`flex flex-col justify-center items-center my-auto min-h-screen text-white w-full`}
      style={{ backgroundColor: "#152a68" }}
    >
      {/* <h1 className="text-3xl mb-5 mt-5">Bionic </h1> */}

      <SessionViewer
        sessions={SessionData}
        currentSessionIndex={currentSessionIndex}
        setCurrentSessionIndex={setCurrentSessionIndex}
        currentPartIndex={currentPartIndex}
        setCurrentPartIndex={setCurrentPartIndex}
      />
    </div>
  );
}

export default SessionsPage;

import React, { useState, useRef, useEffect } from "react";
import { cards } from "../helper/cartData";
import "../css/base25.css";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Button from "./ui/Button";

const Base25 = ({ cardsShowed, setPartFinished }) => {
  const { width, height } = useWindowDimensions();

  const boardGame = useRef();
  const [selectedOptions, setSelectedOptions] = useState(0);
  const [selectedCards, setSelectedCards] = useState([]);

  const handleBoxClick = (id) => {
    const box = boardGame.current.querySelector(`[index="${id}"]`);

    if (box.classList.contains("selected")) {
      box.classList.remove("selected");
      setSelectedCards((prevState) => prevState.filter((card) => card !== id));
      setSelectedOptions(selectedOptions - 1);
    }

    if (selectedOptions < 6 && !selectedCards.includes(id)) {
      box.classList.add("selected");
      setSelectedOptions(selectedOptions + 1);
      setSelectedCards([...selectedCards, id]);
    }
  };

  const handleRevealSelectedCards = () => {
    if (!cardsShowed) {
      //console.log(selectedCards); // TODO REMOVE THE LOG ---
      const boxes = boardGame.current.querySelectorAll(".box");
      boxes.forEach((box) => {
        box.classList.remove("active");
        if (selectedCards.includes(parseInt(box.getAttribute("index")))) {
          box.classList.add("active");
        }
      });
    }
    setPartFinished(true);
  };

  useEffect(() => {
    if (cardsShowed) {
      const boxes = boardGame.current.querySelectorAll(".box");
      boxes.forEach((box) => {
        box.classList.add("active");
      });
    }
  }, [cardsShowed]);

  // reveal all cards
  // const handleRevealAllCards = () => {
  //   const boxes = boardGame.current.querySelectorAll(".box");
  //   console.log(boxes);
  //   boxes.forEach((box) => {
  //     box.classList.add("active");
  //     box.classList.remove("selected");
  //   });
  // };

  const renderedCards = cards.map((card, i) => {
    return (
      <div className="box" key={i} index={i} onClick={() => handleBoxClick(i)}>
        <div className="flipper">
          <div className="front">
            <span className="box_text">{i + 1}</span>
          </div>
          <div className="back">
            <span className="box_text">{card.title}</span>
          </div>
        </div>
      </div>
    );
  });

  // ! Done with css
  // todo set final width and height for landscape and portrait
  // todo if cards areShowed === true then have the cards revealed. And then only able to select the cards but do not flip them.

  return (
    <div className="board_game_container" style={{ width: width }}>
      {cardsShowed && (
        <div className="w-5/6 mb-2 text-center">
          Κάντε κλικ σε 6 από τα τετράγωνα που θα επιλέξετε να έχετε στη ζωή σας
        </div>
      )}
      {!cardsShowed && (
        <div className="w-5/6 mb-2 text-center">
          Κάντε κλικ σε 6 από τα τετράγωνα.
        </div>
      )}
      <div
        className="board_game"
        style={{ width: width * 0.9, height: width * 0.9 }}
        ref={boardGame}
      >
        {renderedCards}
      </div>
      <div className="text-lg mt-1">
        <Button
          rounded
          secondary
          outline={selectedOptions < 6}
          onClick={handleRevealSelectedCards}
          disabled={selectedOptions < 6}
          className={`mt-1 mb-5 ${cardsShowed ? "" : ""}`}
        >
          {cardsShowed ? "Αποθήκευση επιλογών" : "Εμφανιση επιλογών"}
        </Button>
        {/* {cardsShowed && <Button>Hello</Button>} */}
        {/* <Button primary onClick={handleRevealAllCards}>
          Reveal all Cards
        </Button> */}
      </div>
    </div>
  );
};

export default Base25;

const TextArea = ({ placeholder, onChange }) => (
  <div style={styles.container}>
    <textarea
      style={styles.input}
      placeholder={placeholder}
      onChange={onChange}
    ></textarea>
  </div>
);

const styles = {
  container: {
    display: "flex",
    justifyContent: "flex-end",
    width: "90%",
  },
  input: {
    flex: 1,
    margin: "0 auto",
    padding: "10px 0",
    textAlign: "center",
    color: "black",
    fontSize: "12px",
  },
};

export default TextArea;

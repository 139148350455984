import React from "react";

function Instructions({ title, paragraph }) {
  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>{title}</h1>
      {paragraph}
    </div>
  );
}
const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "justify",
  marginLeft: "5%",
  marginRight: "5%",
  width: "90%",
  maxWidth: "600px",
  fontSize: "large",
};

const titleStyle = {
  textAlign: "center",
  marginBottom: "1rem",
  display: "flex",
  flexDirection: "row",
};

export default Instructions;

import React, { useState, useEffect } from "react";

function LoadingSpinner() {
  const [dotIndex, setDotIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDotIndex((prevIndex) => (prevIndex + 1) % 8);
    }, 250); // Adjust the interval duration here (in milliseconds)

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const dots = ["", ".", "..", "...", "....", "...", "..", "."];
  const currentDot = dots[dotIndex];
  return (
    <div
      className="flex items-center justify-center h-screen flex-col "
      style={{ backgroundColor: "#152a68" }}
    >
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
      <span className="ml-3 text-white mt-10 font-bold">
        Loading {currentDot}
      </span>
    </div>
  );
}

export default LoadingSpinner;

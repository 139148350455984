import { useState } from "react";

const Fieldset = ({ legend, questionNum, options, onSelect }) => {
  const [activeOption, setActiveOption] = useState(null);

  const handleOptionClick = (event) => {
    const option = event.currentTarget;
    const value = option.dataset.val;
    setActiveOption(value);
    onSelect(questionNum, value);
  };

  return (
    <div className="fieldset">
      <div className="legend">{legend}</div>
      <div className="rating" data-index={questionNum}>
        {options.map((option, index) => (
          <div
            key={index}
            className={`option ${
              option === Number(activeOption) ? "active" : ""
            }`}
            data-val={option}
            onClick={handleOptionClick}
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Fieldset;

import React, { createContext, useContext, useEffect } from "react";
import useCookie from "../hooks/useCookie";
import { verifyToken } from "../services/apis";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useCookie("token");
  const isAuthenticated = !!token;

  // validate the token
  useEffect(() => {
    const validateToken = async () => {
      if (isAuthenticated) {
        try {
          const response = await verifyToken(token);
          console.log(response); // TODO REMOVE THE LOG
          // Token is valid
        } catch (error) {
          console.log("deleting");
          console.log(error);
          // Token is invalid or expired, perform logout
          logout();
        }
      }
    };

    validateToken();
  }, [isAuthenticated, token]);

  const login = (newToken) => {
    setToken(newToken);
  };

  const logout = () => {
    setToken("");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };

import { useState } from "react";
import Fieldset from "./ui/Fieldset";
import "../css/questionnaires.css";
import Button from "./ui/Button";
import useCookie from "../hooks/useCookie";
import { saveQuestionnaire } from "../services/apis";

const Questionnaires = ({ setPartFinished }) => {
  const [answers, setAnswers] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [token] = useCookie("token");

  //TODO error message

  const handleAnswerSelect = (questionNum, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [`Question ${questionNum}`]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Save questionnaire API
    saveQuestionnaire(token, answers)
      .then((response) => {
        // Data Saved
        setSubmitted(true);
        setPartFinished(true);

        //Save then in the api and proceed to the next part
        console.log(response); // TODO REMOVE THE LOG ---
      })
      .catch((err) => {
        // Something went wrong
        setError(err.data.response.error);
      });
  };

  function validateForm() {
    return Object.keys(answers).length === 12;
  }

  const handleRadioInput = (e) => {
    handleAnswerSelect(11, e.target.value);
  };

  const handleCalendarInput = (e) => {
    handleAnswerSelect(12, e.target.value);
  };

  if (error) {
    alert(error); // Display the error message in an alert dialog
  }

  const today = new Date().toISOString().split("T")[0];

  return (
    <div className="question_container flex justify-center flex-col">
      <h2 className="flex justify-center title">Ερωτηματολόγιο</h2>
      <div className="header">
        <p className="questionnaire">
          Διάβασε προσεκτικά τις πιο κάτω δηλώσεις και επίλεξε για κάθε μια πόσο
          ισχύει για σένα αυτήν τη στιγμή κυκλώνοντας τον κατάλληλο αριθμό. Όταν
          συμπληρώσεις το ερωτηματολόγιο, επέλεξε «Αποθήκευση Απαντήσεων» για να
          μπορέσεις να συνεχίσεις.
        </p>
      </div>

      <div className="sticky">
        <ol className="order-list">
          <li>Διαφωνώ απόλυτα</li>
          <li>Διαφωνώ αρκετά</li>
          <li>Διαφωνώ λίγο</li>
          <li>Ούτε συμφωνώ, ούτε διαφωνώ</li>
          <li>Συμφωνώ λίγο</li>
          <li>Συμφωνώ αρκετά</li>
          <li>Συμφωνώ απόλυτα</li>
        </ol>
      </div>

      <form onSubmit={handleSubmit}>
        <Fieldset
          legend="Έχω επιθυμία για ένα τσιγάρο αυτήν τη στιγμή."
          questionNum="1"
          options={[1, 2, 3, 4, 5, 6, 7]}
          onSelect={handleAnswerSelect}
        />
        <Fieldset
          legend="Τίποτα δε θα ήταν καλύτερο από το να κάπνιζα ένα τσιγάρο τώρα."
          questionNum="2"
          options={[1, 2, 3, 4, 5, 6, 7]}
          onSelect={handleAnswerSelect}
        />
        <Fieldset
          legend="Εάν ήταν δυνατό, πιθανότατα θα κάπνιζα τώρα."
          questionNum="3"
          options={[1, 2, 3, 4, 5, 6, 7]}
          onSelect={handleAnswerSelect}
        />
        <Fieldset
          legend="Θα μπορούσα να ελέγξω καλύτερα τα πράγματα τώρα εάν μπορούσα να καπνίσω."
          questionNum="4"
          options={[1, 2, 3, 4, 5, 6, 7]}
          onSelect={handleAnswerSelect}
        />
        <Fieldset
          legend="Όλα όσα χρειάζομαι αυτήν τη στιγμή είναι ένα τσιγάρο."
          questionNum="5"
          options={[1, 2, 3, 4, 5, 6, 7]}
          onSelect={handleAnswerSelect}
        />
        <Fieldset
          legend="Έχω μια έντονη επιθυμία για τσιγάρο."
          questionNum="6"
          options={[1, 2, 3, 4, 5, 6, 7]}
          onSelect={handleAnswerSelect}
        />
        <Fieldset
          legend="Ένα τσιγάρο τώρα θα είχε ωραία γεύση."
          questionNum="7"
          options={[1, 2, 3, 4, 5, 6, 7]}
          onSelect={handleAnswerSelect}
        />
        <Fieldset
          legend="Θα έκανα σχεδόν οτιδήποτε τώρα για ένα τσιγάρο."
          questionNum="8"
          options={[1, 2, 3, 4, 5, 6, 7]}
          onSelect={handleAnswerSelect}
        />
        <Fieldset
          legend="Το κάπνισμα θα με έκανε λιγότερο θλιμμένο/η."
          questionNum="9"
          options={[1, 2, 3, 4, 5, 6, 7]}
          onSelect={handleAnswerSelect}
        />
        <Fieldset
          legend="Θα καπνίσω το συντομότερο δυνατόν."
          questionNum="10"
          options={[1, 2, 3, 4, 5, 6, 7]}
          onSelect={handleAnswerSelect}
        />

        <div className="fieldset">
          <div className="legend">Καπνίσατε από την τελευταία φορά;</div>
          <div className="smoke">
            <div className="input-item-radio">
              <label htmlFor="smoke_yes">Ναι</label>
              <input
                onChange={handleRadioInput}
                type="radio"
                value="yes"
                name="smoke"
                id="smoke_yes"
              />
            </div>
            <div className="input-item-radio">
              <label htmlFor="smoke_no">Όχι</label>
              <input
                onChange={handleRadioInput}
                type="radio"
                value="no"
                name="smoke"
                id="smoke_no"
              />
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">
            Πότε ήταν η πιο πρόσφατη φορά που καπνίσατε;
          </div>
          <div className="calendar_input">
            <input
              onChange={handleCalendarInput}
              type="date"
              name="calendar"
              style={{ color: "black" }}
              max={today}
              lang="el"
            />
          </div>
        </div>
        {!validateForm() ? (
          <div className=" m-1 text-red-500 text-center">
            Συμπληρώστε όλα τα πεδία για να συνεχίσετε.
          </div>
        ) : null}
        <Button
          secondary
          rounded
          outline={!validateForm() || submitted}
          disabled={!validateForm() || submitted}
          className="ml-0 text-lg"
        >
          {!submitted ? "Αποθήκευση απαντήσεων" : "Ευχαριστώ!"}
        </Button>
      </form>
    </div>
  );
};

export default Questionnaires;

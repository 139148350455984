import React, { useState } from "react";
import "../css/login.css";
import Button from "../components/ui/Button";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { login as apiLogin } from "../services/apis";
import LoadingSpinner from "../components/ui/LoadingSpinner";

const LoginPage = () => {
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  // const [isLoading, setIsLoading] = useState(false); // add a loading state testing only
  const { login } = useAuth();
  const navigate = useNavigate();

  // must be async
  async function handleSubmit(event) {
    event.preventDefault();
    setError(null);
    //setIsLoading(true);

    try {
      // Call the login function from the API
      const token = await apiLogin(code);
      // Pass the token to the login function from the AuthContext
      login(token);
      navigate("/");
    } catch (error) {
      //console.log(error);
      setError(error.response.data.error);
      setCode("");
    }
  }

  const handleChangeCode = (event) => {
    setCode(event.target.value);
  };

  function validateForm() {
    return code.length > 0 && code.length < 10;
  }

  // if (isLoading) {
  //   return <LoadingSpinner />;
  // }

  return (
    <div className="login-container">
      <div className="container">
        <div className="page-name">BioNic</div>

        <form className="login-form" onSubmit={handleSubmit}>
          <label className="input-header">
            <h3 className="text-lg">Κωδικός :</h3>
          </label>
          <input
            autoFocus
            type="password"
            name="password"
            id="password"
            placeholder="Code.."
            value={code}
            onChange={handleChangeCode}
            required
          />
          {error && <p className="text-lg text-red-700">{error}</p>}
          <Button
            outline={!validateForm()}
            primary
            rounded
            disabled={!validateForm()}
            type="submit"
            className="ml-0 mt-4"
          >
            Σύνδεση
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;

import React, { useEffect, useState } from "react";
import Button from "./ui/Button";

const PartViewer = ({
  parts,
  currentPartIndex,
  setCurrentPartIndex,
  time,
  isLastSession,
}) => {
  const currentPart = parts[currentPartIndex];
  // const nextPart = parts[currentPartIndex + 1];
  // console.log(currentPart); // TODO REMOVE THE LOG ---

  const [isPartFinished, setPartFinished] = useState(
    currentPart.isPartFinished
  );

  useEffect(() => {
    setPartFinished(currentPart.isPartFinished);
  }, [currentPart]);

  function handlePartEnding(bool) {
    setPartFinished(bool);
  }

  const handleNextPart = () => {
    if (currentPartIndex < parts.length) {
      //console.log(currentPartIndex); // TODO REMOVE THE LOG ---
      // mark current part as completed
      setCurrentPartIndex(currentPartIndex + 1);
      //get the enabled value of the next part
      //setPartFinished(nextPart.isPartFinished);
    }
  };

  const isLastPart = currentPartIndex === parts.length - 1;
  let isEnabled = isLastPart || !isPartFinished;

  return (
    <>
      <div className="flex flex-col items-center mb-5 w-full">
        {/*<h3>Current Part: {currentPartIndex + 1}</h3>*/}
        <div className="mt-1">
          {currentPart && (
            <currentPart.component
              {...currentPart.props(currentPart.data)}
              setPartFinished={handlePartEnding}
              time={time}
              isLastSession={isLastSession}
            />
          )}
        </div>
      </div>

      {!isLastPart && (
        <div className="flex justify-end max-w-3xl w-full mb-5 text-xl">
          <Button
            disabled={isEnabled}
            outline={isEnabled}
            success
            rounded
            onClick={handleNextPart}
          >
            {currentPartIndex === parts.length - 1 ? "Finish" : "Επόμενο"}
          </Button>
        </div>
      )}
    </>
  );
};
export default PartViewer;

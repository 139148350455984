import React, { useState } from "react";
import NumInput from "./ui/NumInput";
import TextArea from "./ui/TextArea";

const Table = ({ headers, sides, setPartFinished }) => {
  const initialInputValues = headers.map(() => Array(sides.length).fill(""));
  const [inputValues, setInputValues] = useState(initialInputValues);
  //console.log(inputValues);

  const handleInputChange = (headerIndex, sideIndex, value) => {
    // Convert the value to a number
    let numericValue = Number(value);

    // console.log("Input value:", value);
    //console.log("Numeric value:", numericValue);

    // Check if the numericValue is NaN or less than 0, set it to 0
    if (Number.isNaN(numericValue) || numericValue < 0) {
      numericValue = 0;
    }

    // Check if the numericValue is greater than 10, set it to 10
    if (numericValue > 10) {
      numericValue = 10;
    }

    //console.log("Updated numeric value:", numericValue);

    const newInputValues = [...inputValues];
    newInputValues[headerIndex][sideIndex] = numericValue.toString();
    setInputValues(newInputValues);

    // Check if all inputs have non-empty values
    const allInputsFilled = newInputValues.every((header) =>
      header.every((value) => value.trim() !== "")
    );

    //console.log("All inputs filled:", allInputsFilled);

    if (allInputsFilled) setPartFinished(true);
  };

  return (
    <div style={styles.container}>
      <div className=" text-center mr-1 ml-1">
        Συμπληρώστε με αριθμούς από 0 μέχρι 10 <br></br> (0 - πολύ κακό, 10 -
        πολύ καλό)
      </div>
      {headers.map((header, headerIndex) => (
        <div key={headerIndex} style={styles.tableContainer}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th colSpan="2" style={styles.headerCell}>
                  {header}
                </th>
              </tr>
            </thead>
            <tbody className="text-justify">
              {sides.map((side, sideIndex) => (
                <tr key={sideIndex}>
                  <td style={styles.sideCell}>{side}</td>
                  {sideIndex === 2 ? (
                    <td>
                      <TextArea
                        placeholder="π.χ. Περισσότερη προπόνηση"
                        value={inputValues[headerIndex][sideIndex]}
                        onChange={(e) =>
                          handleInputChange(
                            headerIndex,
                            sideIndex,
                            e.target.value
                          )
                        }
                      />
                    </td>
                  ) : (
                    <td>
                      <NumInput
                        placeholder={`0  -  10`}
                        value={inputValues[headerIndex][sideIndex]}
                        onChange={(e) =>
                          handleInputChange(
                            headerIndex,
                            sideIndex,
                            e.target.value
                          )
                        }
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
      {!inputValues.flat().every((value) => value.trim() !== "") && (
        <div className="m-1 text-red-500 text-center">
          Συμπληρώστε όλα τα πεδία για να συνεχίσετε.
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "900px",
  },
  tableContainer: {
    margin: "10px",
  },
  table: {
    width: "100%",

    fontSize: 16,
    fontWeight: "bold",
    borderCollapse: "collapse",
    border: "1px solid #ccc",
  },
  sideCell: {
    padding: "6px",
    color: "#dddddd",
  },
  headerCell: {
    padding: "8px",
    background: "black",
    color: "white",
  },
};

export default Table;

const cards = [
  { title: "Ταξίδι ✈️" },
  // { title: "Get Deported" },
  { title: "Επιτυχία" },
  // { title: "Cheat others" },
  { title: "Ανεργία" },
  { title: "Πολλά Χρέη" },
  // { title: "Change the world" },
  { title: "Φυλάκιση" },
  { title: "Θυμός 👿" },
  // { title: "Get rejected" },
  // { title: "Brave" },
  { title: "Eπιμονή 🧩" },
  { title: "Ευτυχία" },
  // { title: "Hate your job" },
  // { title: "Influence" },
  { title: "Φήμη ⭐" },
  { title: "Φιλοδοξία" },
  { title: "Φθόνος" },
  // { title: "Wisdom" },
  { title: "Εθισμός" },
  { title: "Περιπέτεια 🚶🏽" },
  { title: "Μοναξιά" },
  { title: "Φτώχεια" },
  { title: "Αγάπη ❤️" },
  // { title: "Have fun"},
];

export { cards };

import Button from "../components/ui/Button";
import { useNavigate } from "react-router-dom";

import "../css/landing.css";

function LandingPage() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/sessions");
  };

  return (
    <div className="w-10/12 m-auto flex flex-col items-center h-screen justify-center la_container">
      <p className="la_title">BioNic</p>
      <p className="la_description">
        Καλώς ήρθατε στο διαδικτυακό βοήθημα διακοπής καπνίσματος BioNic. Αυτή η
        εφαρμογή, που απoτελείται από 6 συνεδρίες, θα προσπαθήσει να βοηθήσει
        στο να επιτύχετε μια μακροχρόνια αποχή από το κάπνισμα. Καλή επιτυχία!
      </p>

      <Button
        onClick={handleClick}
        secondary
        rounded
        className="m-5 !p-4 flex items-center px-3 py-1.5 border mx-2 overflow-hidden border-gray-900 bg-gray-900 "
      >
        START
      </Button>
    </div>
  );
}
export default LandingPage;

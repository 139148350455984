const NumInput = ({ value, placeholder, onChange }) => {
  return (
    <input
      type="number"
      style={styles.input}
      min="0"
      max="10"
      maxLength="2"
      value={value || ""}
      placeholder={placeholder}
      onChange={onChange}
      required
    />
  );
};

const styles = {
  input: {
    width: "90%", // increased width
    margin: "0 auto",
    padding: "10px 0",
    textAlign: "center",
    color: "black",
  },
};

export default NumInput;

import Button from "../components/ui/Button";
import "../css/notFoundPage.css";
import { useNavigate } from "react-router-dom";
import useCookie from "../hooks/useCookie";

function NotFoundPage() {
  const navigate = useNavigate();
  const [token] = useCookie("token");
  function handleClick() {
    if (token) navigate("sessions");
    else navigate("");
  }
  return (
    <div className="err_container">
      <h1 className="err_title">404</h1>
      <p className="err_text">Η σελίδα που ψάχνατε δεν βρέθηκε.</p>
      <Button secondary onClick={handleClick} rounded className="m-5 !p-4">
        {token ? "ΕΠΙΣΤΡΟΦΗ ΣΤΙΣ ΣΥΝΕΔΡΙΕΣ" : "ΕΠΙΣΤΡΟΦΗ ΣΤΗΝ ΑΡΧΙΚΗ ΣΕΛΙΔΑ"}
      </Button>
    </div>
  );
}

export default NotFoundPage;

import React, { useState, useEffect, useRef, useContext } from "react";
import PartViewer from "./PartViewer";
import Button from "./ui/Button";
import useCookie from "../hooks/useCookie";
import {
  updatePart,
  getSessionFinished,
  setSessionFinished,
} from "../services/apis";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import "moment/locale/el";

//  Set the locale to Greek
moment.locale("el");

const SessionViewer = ({
  sessions,
  currentSessionIndex,
  setCurrentSessionIndex,
  currentPartIndex,
  setCurrentPartIndex,
}) => {
  const currentSession = sessions[currentSessionIndex];
  const [isNextSessionAvailable, setNextSessionAvailable] = useState(false);
  const [time, setTime] = useState("Unknown");
  const [token] = useCookie("token");
  const { logout } = useAuth();
  const navigate = useNavigate();

  // calculate if it is the final part
  const isFinalPart = currentPartIndex >= currentSession.length - 1;
  const isLastSession = currentSessionIndex === sessions.length - 1;

  const handleNextSession = () => {
    if (!isLastSession) {
      setCurrentPartIndex(0);
      updatePart(token, 0);
      setNextSessionAvailable(false);
      setCurrentSessionIndex(currentSessionIndex + 1);
    } else if (isLastSession && isFinalPart) {
      alert("Σας ευχαριστούμε για τη συμμετοχή σας στο πρόγραμμα.");

      // Log out the user
      logout();
      navigate("/login"); // Redirect to the login page or any other desired location
    }
  };

  useEffect(() => {
    const finishSession = async () => {
      try {
        await setSessionFinished(token, currentSessionIndex);
      } catch (err) {
        console.log(err); // TODO REMOVE THE LOG  ---  TODO Handle the error ---
      }
    };

    const checkNextSessionAvailability = async () => {
      try {
        const nextSessionDate = await getSessionFinished(
          token,
          currentSessionIndex
        );
        const daysUntilNextSession = 7; // TODO PUT THE CORRECT AMOUNT OF DAYS ( NOW IT IS MINUTES)
        const sessionEndDate = new Date(nextSessionDate).getTime();
        //console.log(new Date(sessionEndDate).toString()); // Log sessionEndDate as date and time

        let timeNow = Date.now();

        let enTime =
          sessionEndDate + daysUntilNextSession * 24 * 60 * 60 * 1000 + 3600000;
        //let time = new Date(enTime).toString();
        //console.log(enTime); // Log sessionEndDate as date and time
        //console.log(time); // TODO REMOVE THE LOG ---
        if (timeNow > enTime) {
          setTime("available");
          setNextSessionAvailable(true);
        } else {
          //console.log((timeNow - enTime) / 60000); // TODO REMOVE THE LOG ---
          //const startDate = moment(timeNow); // ! WHY IS THIS NOT USED
          const endDate = moment(enTime);

          const duration = moment().to(endDate, true); // TODO TEST WHEN NEGATIVE
          setTime(duration);
          //console.log("value is: " + duration); // TODO REMOVE THE LOG ---
        }
      } catch (error) {
        console.error("Error fetching next session date:", error);
      }
    };

    const runEffectsInOrder = async () => {
      // if the session ended
      if (isFinalPart) {
        await finishSession();

        if (!isLastSession) {
          // if the session is not the last one
          //console.log("last part not last session");
          await checkNextSessionAvailability();

          // if session is the last one
        } else {
          setTime("done");
          setNextSessionAvailable(true);
        }
      }
    };

    runEffectsInOrder();
  }, [currentSessionIndex, isFinalPart, isLastSession, token]);

  return (
    <>
      <div className="flex flex-row justify-end items-center text-xl mb-3 mt-5">
        {/* <h2>Current Session: {currentSessionIndex + 1}</h2> */}
        <Button
          onClick={handleNextSession}
          secondary
          rounded
          disabled={!isFinalPart || !isNextSessionAvailable}
          outline={!isFinalPart || !isNextSessionAvailable}
          isInvisible={!isFinalPart || !isNextSessionAvailable}
        >
          {isLastSession ? "Tέλος" : "Επόμενη συνεδρία"}
        </Button>
      </div>

      <PartViewer
        parts={currentSession}
        currentPartIndex={currentPartIndex}
        setCurrentPartIndex={setCurrentPartIndex}
        time={time}
        isLastSession={isLastSession}
      />
    </>
  );
};

export default SessionViewer;

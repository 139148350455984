import React, { useEffect, useState } from "react";

function SessionEnding({ session, time, isLastSession }) {
  const [message, setMessage] = useState(null);

  useEffect(() => {
    //console.log(time);
    //console.log(isLastSession);
    if (time === "Unknown") return setMessage(null);
    if (isLastSession) return setMessage(null);
    if (time === "available")
      setMessage(
        <div className="mt-5">
          Για να πάτε στην επομενη, πατήστε το κουμπί:
          <span className=" font-bold"> "Επόμενη συνεδρία"</span>
        </div>
      );
    else if (time !== "done")
      setMessage(
        <div className="mt-5">H επόμενη συνεδρία θα ξεκινήσει σε {time}</div>
      );
    //console.log(message);
  }, [time, isLastSession]);

  return (
    <div className="flex flex-col ml-4 mr-4 text-xl text-justify max-w-xl">
      <div className="mb-5">{session}</div>
      {message}
    </div>
  );
}
export default SessionEnding;

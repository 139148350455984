import axios from "axios";

const baseURL = "https://dev.silversky3d.com/api_bionic"; // TODO Alter it later

// ---------------------------- AUTHENTICATE -------------------------
async function login(code) {
  const res = await axios.post(baseURL + "/auth/login", { code });
  if (res) return res.data.token;
}

async function verifyToken(token) {
  //console.log(token);
  const res = await axios.post(
    baseURL + "/auth/token",
    {},
    {
      headers: { "x-access-token": token },
    }
  );
  if (res) return res.data;
}

//------------------------------------ USER ---------------------------------
async function getSession(token) {
  const res = await axios.get(baseURL + "/user/session", {
    headers: { "x-access-token": token },
  });
  if (res) return res.data.session;
}

async function getPart(token) {
  const res = await axios.get(baseURL + "/user/part", {
    headers: { "x-access-token": token },
  });
  if (res) return res.data.part;
}

async function updateSession(token, session) {
  const res = await axios.put(
    baseURL + "/user/session",
    { session },
    { headers: { "x-access-token": token } }
  );
  if (res) return res.data.session;
}

async function updatePart(token, part) {
  const res = await axios.put(
    baseURL + "/user/part",
    { part },
    { headers: { "x-access-token": token } }
  );
  if (res) return res.data.part;
}

// -------------------  SessionData -------------

async function getSessionFinished(token, session) {
  if (session > 5) {
    //console.log("check your maths");
    return;
  }
  const index = session + 1;
  const res = await axios.get(baseURL + `/session/${index}`, {
    headers: { "x-access-token": token },
  });
  if (res) return res.data;
}

async function setSessionFinished(token, session) {
  const dateNow = Date.now(); // TODO FIX THE FORMAT
  if (session > 5) {
    //console.log("check your maths");
    return;
  }
  const index = session + 1;

  const res = await axios.put(
    baseURL + `/session/${index}`,
    { date: dateNow },
    { headers: { "x-access-token": token } }
  );
  if (res) return res.data;
}

// ---------------- Questionnaire ------------

async function saveQuestionnaire(token, data) {
  return axios
    .post(
      baseURL + "/questionnaire/data",
      { data },
      { headers: { "x-access-token": token } }
    )
    .then((res) => {
      if (res) return res.data;
    });
}

//---------------- Stimuli ------------

async function saveExternal(token, array) {
  //console.log(array);
  //console.log(token);
  return axios
    .post(
      baseURL + "/stimuli/external",
      {
        field1: array[0],
        field2: array[1],
        field3: array[2],
        field4: array[3],
        field5: array[4],
      },
      { headers: { "x-access-token": token } }
    )
    .then((res) => {
      if (res) return res.data;
    });
}

async function saveInternal(token, array) {
  //console.log(array);
  return axios
    .post(
      baseURL + "/stimuli/internal",
      {
        field1: array[0],
        field2: array[1],
        field3: array[2],
        field4: array[3],
        field5: array[4],
      },
      { headers: { "x-access-token": token } }
    )
    .then((res) => {
      if (res) return res.data;
    });
}

async function getExternal(token) {
  return axios
    .get(baseURL + "/stimuli/external", {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res) return res.data;
    });
}

async function getInternal(token) {
  return axios
    .get(baseURL + "/stimuli/internal", {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res) return res.data;
    });
}

export {
  login,
  verifyToken,
  getSession,
  getPart,
  updatePart,
  updateSession,
  setSessionFinished,
  saveQuestionnaire,
  getSessionFinished,
  saveExternal,
  saveInternal,
  getExternal,
  getInternal,
};

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";
import SessionsPage from "./views/SessionsPage";
import LoginPage from "./views/LoginPage";
import LandingPage from "./views/LandingPage";
import NotFoundPage from "./views/NotFoundPage";
import { AuthProvider } from "./context/AuthContext";

import "./App.css";

function App() {
  return (
    <>
      {/* <nav></nav> */}
      <BrowserRouter basename={"/"}>
        <AuthProvider>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<LandingPage />}></Route>
              <Route path="/sessions" element={<SessionsPage />}></Route>
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;

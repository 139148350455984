import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const ProtectedRoute = ({ redirectPath = "/login" }) => {
  //todo Uncomment and other other line
  const { isAuthenticated } = useAuth();
  //const isAuthenticated = true;
  if (isAuthenticated) {
    console.log("is Authenticated: " + isAuthenticated); // TODO REMOVE THE LOG ---
  } else {
    console.log("Redirecting...."); // TODO REMOVE THE LOG ---
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={redirectPath} />;
};

export default ProtectedRoute;

import React, { useState, useEffect } from "react";
import Button from "./ui/Button";
import useCookie from "../hooks/useCookie";
import {
  saveExternal,
  saveInternal,
  getExternal,
  getInternal,
} from "../services/apis";

const TextBoxes = ({ setPartFinished, text, inner, outer }) => {
  const [inputs, setInputs] = useState(["", "", "", "", ""]);
  const [fieldLabels, setFieldLabels] = useState(["", "", "", "", ""]);
  const [isButtonClicked, setIsButtonClicked] = useState(false); // New state variable
  const [token] = useCookie("token");

  const handleInputChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);
  };
  const allInputsFilled = inputs.every((value) => value.trim() !== "");

  const saveAnswers = async () => {
    let res;

    if (inner === "save") {
      res = await saveInternal(token, inputs);
    }

    if (outer === "save") {
      res = await saveExternal(token, inputs);
    }
    if (res) console.log(res.status);
    setIsButtonClicked(true); // Set the state to indicate that the button has been clicked
    setPartFinished(allInputsFilled);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (inner === "get") {
        const data = await getInternal(token);
        if (data) {
          const { field1, field2, field3, field4, field5 } = data;
          setFieldLabels([field1, field2, field3, field4, field5]);
        }
      }

      if (outer === "get") {
        const data = await getExternal(token);
        if (data) {
          const { field1, field2, field3, field4, field5 } = data;
          setFieldLabels([field1, field2, field3, field4, field5]);
        }
      }
    };

    fetchData();
  }, [inner, outer, token]);

  return (
    <div className="flex flex-col items-center justify-center">
      <p className="px-4 py-2 mb-4 text-justify">{text}</p>
      {inputs.map((value, index) => (
        <div key={index} className="px-4 py-1 mb-4 flex-col flex items-center">
          {inner === "get" || outer === "get" ? (
            <div className="mr-2">{fieldLabels[index]}</div>
          ) : null}
          <input
            key={index}
            className="border border-gray-300 rounded px-4 py-2 text-gray-800"
            value={value}
            onChange={(e) => handleInputChange(index, e.target.value)}
          />
        </div>
      ))}

      <Button
        secondary
        rounded
        outline={!allInputsFilled || isButtonClicked}
        onClick={saveAnswers}
        disabled={!allInputsFilled || isButtonClicked}
        className="mt-1 mb-5"
      >
        {isButtonClicked ? "Aποθηκεύτηκαν" : "Αποθήκευση"}
      </Button>
    </div>
  );
};

export default TextBoxes;
